<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Graduation Export</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col>
                                <b-form-group label-for="search-districts" label="District(s):">
                                    <b-input-group size="sm">
                                        <b-select id="search-districts"
                                                  v-model="selectedDistricts"
                                                  :options="districtOptions"
                                                  :select-size="5"
                                                  multiple />
                                        <b-input-group-addon>
                                            <b-button @click="selectedDistricts = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label-for="search-councils" label="Council(s):">
                                    <b-input-group size="sm">
                                        <b-select id="search-councils"
                                                  v-model="selectedCouncils"
                                                  :options="councilOptions"
                                                  :select-size="8"
                                                  multiple />
                                        <b-input-group-addon>
                                            <b-button @click="selectedCouncils = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label-for="search-locals" label="Local(s):">
                                    <b-input-group size="sm">
                                        <b-select id="search-locals"
                                                  v-model="selectedLocals"
                                                  :options="localOptions"
                                                  :select-size="8"
                                                  multiple />
                                        <b-input-group-addon>
                                            <b-button @click="selectedLocals = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label-for="search-sessions" label="Session(s):">
                                    <b-input-group size="sm">
                                        <b-select id="search-sessions"
                                                  v-model="selectedSessions"
                                                  :options="sessionOptions"
                                                  :select-size="8"
                                                  multiple />
                                        <b-input-group-addon>
                                            <b-button @click="selectedSessions = []">
                                                <font-awesome-icon prefix="fas" icon="times"/>
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-group label-for="graduation-year" label="Graduation Year:">
                                    <picker-of-dates id="graduation-year"
                                                     v-model="selectedYear"
                                                     format="yyyy"
                                                     type="year"
                                                     placeholder="Select Year"
                                                     clearable/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import PickerOfDates from '@/components/shared/PickerOfDates';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import download from '@/util/downloader';
import _ from 'underscore';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        PickerOfDates
    }
})

export default class GraduationExport extends Vue {
    selectedDistricts = [];
    selectedCouncils = [];
    selectedLocals = [];
    selectedSessions = [];
    selectedYear = null;

    get districtOptions() {
        return _.map(this.$store.getters['common/districts'], (d) => {
            return {
                value: d.id,
                text: d.name
            };
        });
    }

    get councilOptions() {
        const councils = _.groupBy(this.$store.getters['common/councils'], (c) => c.districtName);
        return _.map(councils, (cs, district) => {
            return {
                label: district,
                options: _.map(cs, (c) => {
                    return {
                        value: c.id,
                        text: sprintf('%s (%s)', c.name, c.displayId)
                    };
                })
            };
        });
    }

    get localOptions() {
        const locals = _.groupBy(this.$store.getters['common/locals'], (l) => sprintf("%s - %s", l.districtName, l.councilName));
        return _.map(locals, (ls, districtCouncil) => {
            return {
                label: districtCouncil,
                options: _.map(ls, (local) => {
                    return {
                        value: local.id,
                        text: sprintf("%s - %s, %s", local.displayId, local.city, local.state)
                    };
                })
            };
        });
    }

    get sessionOptions() {
        const sessions = this.$store.getters['common/program4Sessions'];
        const options = _.map(sessions, s => {
            return {
                text: s.value,
                value: s.id
            };
        });
        return options;
    }

    clearFilters() {
        this.selectedDistricts = [];
        this.selectedCouncils = [];
        this.selectedLocals = [];
        this.selectedSessions = [];
        this.selectedYear = null;
    }

    async getCsv() {
        try {
            await download('POST', '/api/v1/report/graduation-export', JSON.stringify({
                graduationYear: this.selectedYear,
                districtIds: this.selectedDistricts,
                councilIds: this.selectedCouncils,
                localIds: this.selectedLocals,
                sessionIds: this.selectedSessions
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Graduation Export', null, true));
        try {
            await this.$store.dispatch('common/loadProgram4Sessions');
            _.each([
                'common/loadDistricts',
                'common/loadCouncils',
                'common/loadLocals'
            ], (action) => {
                this.$store.dispatch(action)
                    .catch(error => {
                        this.$bvToast.toast(error.message, errorToastOptions);
                    });
            });
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }
}
</script>